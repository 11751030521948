
    /* Variables - Select some DOM Elements
    //////////////////////////////////////// */
    
    var pageTitle = document.querySelector('title'),
    logoWrap = document.querySelector('.company-logo'),
    logo = document.querySelector('.company-logo a img'),
    companyName = document.querySelector('.company-name'),
    tagLine = document.querySelector('.company-motto'),

    portraitImg = document.querySelector('.xs-profile-picture img'),

    primaryNav = document.querySelector('.xs-primary-navigation'),
    secondaryNavRow1 = document.querySelector('.xs-secondary-navigation-row-1'),
    secondaryNavRow2 = document.querySelector('.xs-secondary-navigation-row-2'),
    secondaryNavRow3 = document.querySelector('.xs-secondary-navigation-row-3'),
    secondaryNav = document.querySelector('.xs-secondary-navigation'),
    altNav = document.querySelector('.xs-alt-navigation');

    window.collection = [];


    /* Functions
    //////////////////////////////////////// */

    // Create a generic DOM Element
    var createEl = function(el, elText, elClass) {
        var newEl = document.createElement(el);
        if (elText) {newEl.textContent = elText;}
        if (elClass) {newEl.className = elClass;}
        
        return newEl;
    };// /makeElem()


    // make a SVG
    // called in the nav loop to make tiny arrows :)
    var createSvg = function(w, h, elClass) {
        var svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svgEl.setAttribute('width', w + 'px');
        svgEl.setAttribute('height', h + 'px');
        svgEl.setAttribute('viewBox', '0 0 ' + w + ' ' + h);
        svgEl.setAttribute('class', elClass);

        return svgEl;
    };// /createSVG()


    // make a SVG ARROW <path>
    // make an arrow
    var createArrowPath = function() {
        var arrowPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        arrowPath.setAttribute('fill', 'black');        
        arrowPath.setAttribute('d', 'M5,4.598l-5,4.402l0,-8.997l5,4.595Z');
        return arrowPath;
    };// /createArrowPath()
    

    // Copy Color style to parent element
    // referenced in Company Name and Tagline
    var copyColor = function(colorData, el) {
        var colorStart = colorData.indexOf('color=') + 7;
        var colorEnd = colorStart + 7;// returning a hex value
        var color = colorData.slice(colorStart, colorEnd);
        el.style.color = color;
    };// /copyColor()

    // Copy Font size to parent element
    // referenced in Company Name and Tagline
    var copyFontSize = function(fontData, el) {
        var sizeStart = fontData.indexOf('size=') + 6;
        var sizeEnd = sizeStart + 2;
        var fontSize = fontData.slice(sizeStart, sizeEnd);
        el.style.fontSize = fontSize + "px";
    };// /copyFontSize()

    // Set the X/Y postion of an element
    var setPosition = function(el) {
        // xmult & ymult set in Header.ascx
        var xPos = el.getAttribute('data-xpos') * xmult,
            yPos = el.getAttribute('data-ypos') * ymult;
        el.style.left = xPos + 'px';
        el.style.top = yPos + 'px';
    };// /setPosition()


    // add page content from strAccountData
    // mostly the header stuff
    var renderPageItems = function() {

        var data = strAccountData[0];


        // Add company Name
        if ( data.DisplayCompName === 1 && companyName && data.CompanyName !== null ) {

            // Company Name Color
            copyColor(data.CompanyName, companyName);

            // Company name font size
            copyFontSize(data.CompanyName, companyName);

            companyName.innerHTML = decodeURIComponent(data.CompanyName).replace(/size=/g, 'data-size=').replace(/color=/g, 'data-color=');

            // Set X/Y positions
            setPosition(companyName);

        }
        
        // Company Motto/Tagline
        if ( data.CompanyTagline !== '' && tagLine ) {

            // Motto/Tagline Color
            copyColor(data.CompanyTagline, tagLine);

            // Company Motto/Tagline size
            copyFontSize(data.CompanyTagline, tagLine);

            // add Tagline
            tagLine.innerHTML = decodeURIComponent(data.CompanyTagline).replace(/size=/g, 'data-size=').replace(/color=/g, 'data-color=');

            // Set X/Y positions
            setPosition(tagLine);
        }

        // Company Logo
        if ( data.DisplayLogo === 1 && logo && (/\.(gif|jp?g|tiff|png)$/i).test(data.CompanyLogoURL)) {
            logoWrap.style.display = 'block';
            logo.setAttribute('src', data.CompanyLogoURL);

            // Set X/Y positions
            setPosition(logoWrap);
        }

        // Sidebar Photo/Portrait
        if ( data.DisplayPortrait === 1 && data.PortraitURL !== '' && portraitImg && (/\.(gif|jp?g|tiff|png)$/i).test(data.PortraitURL)) {
            portraitImg.setAttribute('src', data.PortraitURL);
            document.querySelector('.xs-profile-picture').style.display = 'block';
        }
        
    }();// /renderPageItems() **immediately invoked**


    // Create navigation listitems
    var createListItem = function ( anchor ) {
		if (!String.prototype.includes) {
        String.prototype.includes = function (str) {
            var returnValue = false;

            if (this.indexOf(str) !== -1) {
                returnValue = true;
            }

            return returnValue;
        }
    }
        var page = anchor.PageIndex;

        // create a <li>
        var listItem = document.createElement('li');
        listItem.setAttribute('data-page', page);

        // if there's a link add the link
        if (anchor.PageURL) {
            var theLink = createEl('a', anchor.DisplayPageName);
            theLink.setAttribute('href', anchor.PageURL.includes('http:') || anchor.PageURL.includes('https:') ? anchor.PageURL : '/' + anchor.PageURL);
            listItem.appendChild(theLink);  
        }

        // if indented add 'indent' class
        if ( anchor.Indent === true ) {
            if (listItem.classList) {
                listItem.classList.add('indent');
            } else {
                listItem.className += ' ' + 'indent';
            }
        }
        
        // add Sub Nav <ul class="sub-nav"> if menu HasChildren
        if ( anchor.HasChildren !== 0 ) {
            var elName = anchor.FileName.replace(/.x/g, '');
            var subNav = document.createElement('ul');
            subNav.setAttribute('id', 'sub-nav-' + elName);
            subNav.className = 'sub-nav';

            // add 'has-children' class to <li>
            if (listItem.classList) {
                listItem.classList.add('has-children');
            } else {
                listItem.className += ' ' + 'has-children';
            }
            
            // make a SVG container element
            var svg = createSvg(5, 9, 'sub-indicator');
            // make an Arrow >
            var arrow = createArrowPath();
            svg.appendChild(arrow);

            // prepend the svg to the linkg
            listItem.insertBefore(svg, listItem.firstChild);

            // append the sub-nav <ul> to the <li>
            listItem.appendChild(subNav);
        }

        // Connect the nested navigation to the parent
        if ( anchor.Location == "Child" ) {
            var parentName = anchor.Parent.replace(/.x/g, '');
            listItem.setAttribute('data-parent', parentName);
        }

        return listItem;

    };

    // Add primary navigation listitems to the ul
    var createPrimaryNav = function () {
        
        // Loop through six times
        // Since all layouts will have 6 navigation items
        for ( var i = 0 ; i < 6 ; i++ ) {

            // If `window.collection` at the index of `i` is not
            // undefined, then append the element stored in `window.collection[i]`
            if ( window.collection[i] ) {
                primaryNav.appendChild( window.collection[i] );
                continue;
            }

            // If no data exists in current interation of `window.collection` 
            // Create and append a spacer list item to the `primaryNav`
            var spacer = document.createElement('li');
            spacer.className = 'spacer';

            primaryNav.appendChild( spacer );
        }
    };

    // Add secondary navigation listitems to the ul
    var createSecondaryNav = function ( ) {
        var data = strAccountData[0];

        if (data.ThemeFamily == 'Urban') {
            for ( var i in window.collection ) {
                if (i <= 4) {
                    secondaryNavRow1.appendChild( window.collection[i] );
                } else if (i <= 9) {
                    secondaryNavRow2.appendChild( window.collection[i] );
                } else if (i <= 14) {
                    secondaryNavRow3.appendChild( window.collection[i] );
                } else {
                    secondaryNav.appendChild( window.collection[i] );
                }
            }
        } else if (data.ThemeFamily == 'Top') {
            for ( var i in window.collection ) {
                secondaryNavRow1.appendChild( window.collection[i] );
            }
        } else if (data.ThemeFamily == 'Fly') {
            for ( var i in window.collection ) {
                if (i <= 14) {
                    secondaryNavRow1.appendChild( window.collection[i] );
                } else {
                    secondaryNav.appendChild( window.collection[i] );
                }
            }
        } else {
            for ( var i in window.collection ) {
                secondaryNav.appendChild( window.collection[i] );
            }
        }
    };

    // Add alt navigation listitems to the ul
    var createAltNav = function () {
        for ( var i in window.collection ) {
            altNav.appendChild( window.collection[i] );
        }
    };

    // Add nested/child navigation listitems to the ul under the correct parent
    var createChildNav = function () {
        for ( var i in window.collection ) {
            var listElem = document.getElementById('sub-nav-' + window.collection[i].getAttribute('data-parent'));
            listElem && listElem.appendChild(window.collection[i]);
        }
    };


    // All organized content data
    window.organizedContent = {
        primary: [],
        secondary: [],
        alt: [],
        child: []
    };

    // Add navigation data to arrays based on location
    for (var i in strPageData) {
        var locale = strPageData[i].Location.toLowerCase();
        window.organizedContent[locale] && window.organizedContent[locale].push( strPageData[i] );
    }


    // Functions to create arrays of data sorted by location
    buildNav( window.organizedContent.primary, 'primary' );
    buildNav( window.organizedContent.secondary, 'secondary' );
    buildNav( window.organizedContent.alt, 'alt' );
    buildNav( window.organizedContent.child, 'child' );

    function buildNav ( target, location ) {
        window.collection = [];

        // Creates array of navigation objects with data
        if (location == 'child') {
            for (var i = 0; i < target.length; i++) {

                var anchor = target[i];
                var page = anchor.PageIndex;

                // Add objects to array with data
                window.collection[i] = anchor;
            } 
        } else {
            for (var i = 0; i < target.length; i++) {

                var anchor = target[i];
                var page = anchor.PageIndex;
                
                // Add objects to array with data by page number order
                window.collection[page] = anchor;
            }       
        } 

        if (location == 'child') {
            // Make list items of child navigation elements without spacers and with parent page data attached
            for (var i = 0; i < window.collection.length; i++) {
                if ( window.collection[i] !== undefined ) {
                    window.collection[i] = createListItem(window.collection[i]);
                } 
            }
        } else if (location == 'alt') {
            // Make list items of alt navigation elements without spacers
            for (var i = 1; i < window.collection.length; i++) {
                if ( window.collection[i] !== undefined ) {
                    window.collection[i] = createListItem(window.collection[i]);
                } 
            }

            window.collection.shift();
        } else {
            // Make list items of primary and secondary navigation elements with spacers
            for (var i = 1; i < window.collection.length; i++) {
                if ( window.collection[i] === undefined ) {
                    var spacer = document.createElement('li');
                    spacer.className = 'spacer';

                    window.collection[i] = spacer;
                } else {

                    window.collection[i] = createListItem(window.collection[i]);
                }
            }

            window.collection.shift();
        }  

        // append the list item to the appropriate <ul>
        if ( location == 'primary' && primaryNav ) {
            createPrimaryNav();
        } else if ( location == 'secondary' && secondaryNav ){
            createSecondaryNav();
        } else if ( location == 'child' ) {
            createChildNav();
        } else if ( location == 'alt' && altNav ) {
            createAltNav();
        }

    }


    // Add wmode="transparent" to Flash Movies/YouTubes so's the sub-nav fly-outs can be viewed over them
    
    // https://helpx.adobe.com/flash/kb/flash-object-embed-tag-attributes.html#main_Browser_support_for_Window_Mode__wmode__values
    $(function () {
        $('object').each(function () {
            var wrap, clone;
            
            wrap = $('<div>').addClass('embed-wrapper');
            
            clone = $(this).clone();
            clone.append(
                $('<param>').attr({
                    name: 'wmode',
                    value: 'transparent'
                })
            ).find('embed').attr('wmode', 'transparent');
    
            $(this).before( wrap.append(clone) );
            $(this).remove();
        });

        $('iframe[src*="youtube"]').each(function () {
            var src = $(this).attr('src');

            src += (src.match(/\?/g) ? '&' : '?' ) + "wmode=transparent";
            
            $(this).attr({
                src: src,
                wmode: "Opaque"
            });
        });
    
        //override bootstrap table cellspacing for legacy tables :'(
        $('.xs-page-content table[cellspacing]').each(function() {
           var space = $(this).attr('cellspacing') + 'px';
           $(this).css('border-spacing', space);
           $(this).css('border-collapse', 'separate');
        });


        // Hide images if 404/403
        // $('.company-logo a img').on('error', function() {
        //     $(this).hide();
        // });

        // $('.xs-profile-picture img').on('error', function() {
        //     $(this).hide();
        // });

    });


